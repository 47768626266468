import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"

import { Title } from "src/componentsV2/sections/Text"
import { ContainerPage, WrapperPage } from "src/componentsV2/ui/layout"
import Testimonials from "src/componentsV2/sections/Testimonials"
import { brandsData } from "src/componentsV2/sections/Testimonials/data"

import Industries from "./Industries"

const TrustedByIndustryBrandsStyled = styled.div`
  ${tw`text-center`}
  .title {
    margin-bottom: 50px;
  }
  .industry-wrapper {
    flex-direction: column-reverse;
    .testimonial-slider {
      .description {
        font-family: Lato;
        font-style: italic;
        font-size: 17px;
      }
    }
  }
  .testimonials {
    padding: 0px 10px 24px;
    .testimonial-slider {
      .card-slide {
        min-height: 520px;
        padding: 45px 20px 0px;
        .image {
          img {
            object-fit: contain;
          }
        }
        .circle {
          display: none;
        }
        &.rc-item {
          .image {
            img {
              border: none;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 1024px) {
    .industry-wrapper {
      flex-direction: row;
      .testimonial-slider {
        .description {
          font-size: 21px;
        }
      }
    }
    .title {
      ${tw`text-center mb-18`}
    }
    .testimonials {
      margin: 0;
    }
  }
  @media screen and (min-width: 768px) {
    .title {
      ${tw`text-center mx-auto mb-18`}
      max-width: 500px;
    }
    .testimonials {
      padding: 0px 24px 24px;
      .testimonial-slider {
        .card-slide {
          padding: 65px 66px 70px;
        }
      }
    }
  }
`

export const TrustedByIndustryBrands = () => {
  return (
    <ContainerPage
      isBackgroundWhite
      data-loadable-component="trusted-by-industry-brands"
    >
      <WrapperPage>
        <TrustedByIndustryBrandsStyled>
          <Title className="mb-10 mt-6 px-8 md:px-0 title">
            Trusted by businesses and HR teams around the world
          </Title>
          <div className="industry-wrapper flex justify-between item-start">
            <Testimonials className="testimonials" data={brandsData} />
            <Industries />
          </div>
        </TrustedByIndustryBrandsStyled>
      </WrapperPage>
    </ContainerPage>
  )
}
