import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"

import anytimeFitness from '../images/AnytimeFitness.svg'
// import ghiradelli from '../images/Ghiradelli.svg'
import elastic from '../images/CL_Elastic.svg'
import lacoste from '../images/Lacoste.svg'
import mattel from '../images/Mattel.svg'
import pg from '../images/PG.svg'
import rackspace from '../images/Rackspace.svg'
import softBank from '../images/Softbank.svg'
import sohoHouse from '../images/Sohohouse.svg'
import timHortons from '../images/TimHortons.svg'
import usPollo from '../images/USPoloRevised.svg'

interface IIndustryImage {
  image: any
  width: number
  height: number
  title: string
  alt: string
  className?: string
}

// should define width height to improve CLS
// https://web.dev/optimize-cls/?utm_source=lighthouse&utm_medium=unknown#images-without-dimensions
const industriesImages: IIndustryImage[] = [
  { image: lacoste, width: 140, height: 60, alt: "lacoste", title: "Lacoste Employee Handbook" },
  { image: sohoHouse, width: 140, height: 65, alt: "soho_house", title: "Soho House Employee Handbook", className: "soho-house" },
  { image: usPollo, width: 140, height: 30, alt: "us_polo_assn", title: "U.S. Polo Assn. Employee Handbook" },
  { image: mattel, width: 94, height: 94, alt: "mattel", title: "Mattel Employee Handbook" },
  { image: pg, width: 118, height: 52, alt: "procter_and_gamble", title: "Procter & Gamble Employee Handbook" },
  { image: elastic, width: 118, height: 52, alt: "elastic", title: "Elastic Employee Handbook" },
  { image: rackspace, width: 118, height: 52, alt: "rackspace_technology", title: "Rackspace Technology Employee Handbook" },
  { image: timHortons, width: 126, height: 76, alt: "tim_hortons", title: "Tim Hortons Employee Handbook" },
  { image: softBank, width: 124, height: 76, alt: "softBank_group", title: "SoftBank Group Employee Handbook" },
  { image: anytimeFitness, width: 140, height: 33, alt: "anytime_fitness", title: "Anytime Fitness Employee Handbook" },
]

const IndustriesStyled = styled.div`
  ${tw`flex flex-wrap flex-row mb-3 items-center justify-center`}

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto 35px;
    flex: 1 1 50%;
    padding: 0px 15px;
    max-width: 380px;
    margin-left: auto;

    img {
      max-width: 85px;
      max-height: 65px;
      width: auto;
      height: auto;

      @media screen and (min-width: 768px) {
        max-width: 140px;
        max-height: 95px;
      }

      &.soho-house {
        max-width: 110px;

        @media screen and (min-width: 768px) {
          max-width: 160px;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    max-width: 440px;
    margin-left: auto;
    margin-top: -33px;
    margin-bottom: -5px;
    .item {
      margin: 0px;
      align-items: center;
    }
  }
`

const Industries = () => {
  return (
    <IndustriesStyled>
      {industriesImages.map((industryImage, idx) => (
        <div className="item" key={idx} title={industryImage.title}>
          <img
            data-src={industryImage.image}
            alt={industryImage.alt}
            width={industryImage.width}
            height={industryImage.height}
            className={industryImage.className || ""}
          />
        </div>
      ))}
    </IndustriesStyled>
  )
}

export default Industries
