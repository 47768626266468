import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import { css } from "@emotion/core"

import { switchPage } from "src/utils/navigateTo"
import bannerImgDesktopPNG from "src/images/home/banner_img_705x657.png"
import bannerImgDesktopWEBP from "src/images/home/banner_img_705x657.webp"
import bannerImgMobilePNG from "src/images/home/banner_img_395x368.png"
import bannerImgMobileWEBP from "src/images/home/banner_img_395x368.webp"
import { Col , ContainerPage, WrapperPage } from "src/componentsV2/ui/layout"
import { Title, SubTitle } from "src/componentsV2/sections/Text"
import { ButtonTransparent, BookDemoButton } from "src/componentsV2/ui/Button"


import { HeroBannerContainer } from "./style"

const HeroContainer = styled(ContainerPage)`
  padding-top: 20px;
  padding-bottom: 0;
  margin-bottom: 2rem;
  padding-top: 90px;

  @media screen and (min-width: 768px) {
    margin-bottom: 0px;
    padding-top: 160px;
  }
`

const ColStyled = styled(Col)`
  ${tw`text-center`}
  width: 100%;
  @media (min-width: 768px) {
    min-width: auto;
  }
`

const RowStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`

const HeroBanner = () => {
  return (
    <HeroContainer data-loadable-component='hero-banner__landing'>
      <WrapperPage className="mt-8">
        <HeroBannerContainer>
          <RowStyled>
            <ColStyled className="md:max-w-sm">
              <Title className="mb-5 mt-6 px-8 md:px-0">
                Your digital employee handbook builder
              </Title>
              <SubTitle>
                <p>
                  Design creatively, update instantly and distribute easily,
                  with many beautiful templates to choose from.
                </p>
                <p>
                  <span
                    className="text-orange-2 underline cursor-pointer md:text-gray-1 
                  md:no-underline md:cursor-default md:pointer-events-none"
                    onClick={() => switchPage.bookDemo()}
                  >
                    Book a demo
                  </span>
                  {" "}
                  or try it today for FREE
                </p>
              </SubTitle>
              <div className="hidden items-center md:flex">
                <BookDemoButton
                  onClick={() => switchPage.bookDemo()}
                  style={{ height: 50, width: 210, borderRadius: 30 }}
                />
                <div className="mx-6">Or</div>
                <ButtonTransparent
                  onClick={switchPage.plans}
                  css={css`
                    width: 210px;
                  `}
                >
                  Start your free trial
                </ButtonTransparent>
              </div>
            </ColStyled>
            <ColStyled>
              <picture>
                <source srcSet={bannerImgDesktopWEBP} type="image/webp" media="(min-width: 425px)" />
                <source srcSet={bannerImgDesktopPNG} type="image/png" media="(min-width: 425px)" />
                <source srcSet={bannerImgMobileWEBP} type="image/webp" />
                <source srcSet={bannerImgMobilePNG} type="image/png" />
                <img data-src={bannerImgDesktopPNG} alt="hero-banner" width="705" height="657" />
              </picture>
            </ColStyled>
          </RowStyled>
        </HeroBannerContainer>
      </WrapperPage>
    </HeroContainer>
  )
}

export default HeroBanner
